import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";

const LocalLoadingPages = (): JSX.Element => {
  const { palette } = useTheme();
  const {
    location: { pathname },
  } = useHistory();

  return (
    <Backdrop
      sx={{
        bgcolor: "red",
        zIndex: 900,
        position: "absolute",
        borderRadius: "8px",
        visibility: "hidden",
      }}
      open={true}
    >
      <Stack alignItems="center">
        {pathname === "/explore-tasks" ? (
          <Box component="img" src="/images/taskpin-logo.svg" width="192px" />
        ) : null}
        <CircularProgress
          size={50}
          sx={{ color: palette.primary.main, fontSize: 20, mt: "48px" }}
        />
        <Typography
          component="span"
          variant="subtitle1"
          sx={{ color: palette.neutral[900], mt: 2 }}
        >
          Loading...
        </Typography>
      </Stack>
    </Backdrop>
  );
};

export default LocalLoadingPages;
