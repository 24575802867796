import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useTheme,
  Popover,
  keyframes,
} from "@mui/material";
import useAccountInfoStore from "store/zustand/accountInformation";
import { Icon } from "components/Icon";
import { useHistory } from "react-router-dom";
import { fetchPersonaLink } from "api/profile/index";

export const GetVerifiedModal = ({ id, open, anchorEl, handleClose }: any) => {
  const { palette } = useTheme();

  const getVerified = keyframes` 
  0% {
   opacity: 0;
   margin-right:50px;
  }
 
  100% {
    opacity: 1;
    margin-right:0px;
  }`;

  const { push } = useHistory();

  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );

  const openVerified: any = useAccountInfoStore((state) => state.openVerified);

  const setOpenVerified: any = useAccountInfoStore(
    (state) => state.setOpenVerified
  );

  const handleClickPersona = () => {
    fetchPersonaLink()
      .then((res) => {
        if (res.data.hosted_flow_link)
          window.open(res.data.hosted_flow_link, "_blank");
        setOpenVerified("");
      })
      .catch((err) => {
        setOpenVerified("");
      });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onClick={handleClose} 
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      BackdropProps={{ invisible: true }} 
      sx={{
        ".MuiPopover-paper": {
          borderRadius: "16px",
          boxShadow: "unset !important",
          mt: { md: -10, xs: 4 },
          width: { xs: "100%", md: "100%" },
          height: "100%",
          display: { md: "unset", xs: "none" },
          bgcolor: "unset",
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          width: "360px",
          position: "absolute",
          bottom: 5,
          right: 20,
          boxShadow: "0px 0px 8px 0px #00000040",
          borderRadius: "16px",
          bgcolor: palette.neutral.min,
          py: 2,
          px: 3,
          zIndex: 999,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">
              {Math.round(AccountInfo?.account_status?.percentage.toFixed(2))}%
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: palette.neutral[600], ml: 1 }}
            >
              Completed
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{ color: palette.neutral[900], mt: 1 }}
          >
            Complete your profile to get Verified Badge
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: palette.neutral[600], mt: 0.5 }}
          >
            How Verified Badge help me?
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.basic_info_completed
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "account" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Name & About
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                position: "absolute",
                right: 23,
                color: palette.success.main,
                display:
                  AccountInfo?.account_status?.basic_info_completed &&
                  AccountInfo.about
                    ? "flex"
                    : "none",
                animation:
                  AccountInfo?.account_status?.basic_info_completed &&
                  openVerified === "about" &&
                  AccountInfo.about
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  position: "absolute",
                  right: 23,
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display:
                  AccountInfo?.account_status?.basic_info_completed &&
                  AccountInfo.about
                    ? "none"
                    : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.mobile
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "account" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Mobile Number
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                color: palette.success.main,
                display: AccountInfo?.account_status?.mobile ? "flex" : "none",
                animation:
                  AccountInfo?.account_status?.mobile &&
                  openVerified === "mobile"
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display: AccountInfo?.account_status?.mobile ? "none" : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.biling_address
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "payment-settings" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Billing Address
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                color: palette.success.main,
                display: AccountInfo?.account_status?.biling_address
                  ? "flex"
                  : "none",
                animation:
                  AccountInfo?.account_status?.biling_address &&
                  openVerified === "billing"
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display: AccountInfo?.account_status?.biling_address
                  ? "none"
                  : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.bank_account
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "payment-settings" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Bank Account
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                color: palette.success.main,
                display: AccountInfo?.account_status?.bank_account
                  ? "flex"
                  : "none",
                animation:
                  AccountInfo?.account_status?.bank_account &&
                  openVerified === "bank"
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display: AccountInfo?.account_status?.bank_account
                  ? "none"
                  : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.portfolio
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "services" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Services
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                color: palette.success.main,
                display: AccountInfo?.account_status?.portfolio
                  ? "flex"
                  : "none",
                animation:
                  AccountInfo?.account_status?.portfolio &&
                  openVerified === "services"
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display: AccountInfo?.account_status?.portfolio
                  ? "none"
                  : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.skills
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "skills" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Skills
            </Typography>
            <Icon
              iconSet="CircleCheck-Filled"
              sx={{
                color: palette.success.main,
                display: AccountInfo?.account_status?.skills ? "flex" : "none",
                animation:
                  AccountInfo?.account_status?.skills &&
                  openVerified === "skills"
                    ? `${getVerified} 0.5s ease-in-out forwards`
                    : "",
              }}
            />
            <Icon
              iconSet="ArrowCircle-Right"
              sx={{
                color: palette.primary.main,
                "&:hover": {
                  cursor: "Pointer",
                  fontVariationSettings: `"FILL" ${1}`,
                },
                display: AccountInfo?.account_status?.skills ? "none" : "flex",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.identity_check
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              // push({ pathname: "/identity-check" });
              if (AccountInfo?.account_status?.identity_check === false)
                handleClickPersona();
            }}
          >
            <Typography component="span" variant="subtitle2">
              Verify your Identity
            </Typography>
            {AccountInfo?.account_status?.identity_check ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
