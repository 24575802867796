import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import Cookies from "js-cookie";

interface DraftJobProps {
  handleDraft: (value: boolean) => void;
  setTaskData: Dispatch<SetStateAction<{ [key: string]: any }>>;
  draftName?: any;
}

export const DraftJob = ({
  handleDraft,
  setTaskData,
  draftName,
}: DraftJobProps): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));

  const hanldeRemoveDraft = () => {
    Cookies.remove("draft");
    handleDraft(false);
  };

  const hanldeResumeDraft = () => {
    const cookie = Cookies.get("draft");
    if (typeof cookie === "undefined") {
      return;
    } else {
      setTaskData(JSON.parse(cookie));
    }
    handleDraft(false);
    Cookies.remove("draft");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: { xs: "100%", sm: "558px" } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: { xs: 3, md: 1 },
          }}
        >
          <ErrorOutlineRoundedIcon
            sx={{
              display: { xs: "none", md: "flex" },
              width: "26.67px",
              height: "26.67px",
              mr: "10px",
              color: palette.primary.main,
            }}
          />
          <Typography variant="h5" sx={{ color: palette.neutral.max }}>
            Resume posting your task where you left off
          </Typography>
        </Box>
        <Box sx={{ pr: 5.5 }}>
          <Typography variant="body1">
            Your task &quot;{JSON.parse(draftName).title}&quot; is almost ready
            to pin. Would you like to resume where you left off?
          </Typography>
        </Box>
        <Box
          sx={{
            mt: { xs: 5, md: 2 },
            mb: "18px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            fullWidth={smallSize ? true : false}
            onClick={hanldeResumeDraft}
            sx={{
              color: { xs: palette.neutral.min, md: palette.primary.main },
              backgroundColor: {
                xs: palette.primary.main,
                md: palette.primary[50],
              },
              mr: { xs: 0, md: 1 },
              height: { md: 35, xs: 40 },
              "&:hover": {
                backgroundColor: "#E3E5FF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            <Typography variant="button">Resume Task</Typography>
          </Button>
          <Button
            onClick={hanldeRemoveDraft}
            sx={{
              display: { xs: "none", md: "flex" },
              mt: { xs: 2, md: 0 },
              color: palette.neutral[700],
              backgroundColor: palette.neutral.min,
              border: `1px solid ${palette.neutral[400]}`,
              height: { md: 35, xs: 40 },
            }}
          >
            <Typography variant="button">Dismiss</Typography>
          </Button>
          <Button
            sx={{
              display: { md: "none", xs: "flex" },
              mt: { xs: 2, md: 0 },
              color: palette.primary.main,
              backgroundColor: palette.primary[50],
              height: { md: 35, xs: 40 },
            }}
            onClick={hanldeRemoveDraft}
          >
            <Typography variant="button">Pin a new Task</Typography>
          </Button>
        </Box>
        <Divider sx={{ mb: "26px" }}>
          <Typography
            variant="caption"
            sx={{ color: palette.neutral[300], textAlign: "center", px: 1 }}
          >
            or
          </Typography>
        </Divider>
      </Box>
    </Box>
  );
};
