import axios from "axios";
import { isDevelopment } from "utils";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v1",
});
API.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (isDevelopment) {
      config.headers["CF-Access-Client-Id"] =
        process.env.REACT_APP_ACCESS_CLIENT_ID || "";
      config.headers["CF-Access-Client-Secret"] =
        process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET || "";
    }
    return config;
  },
  (err) => Promise.reject(err)
);
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const APIV2 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v2",
});

APIV2.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (isDevelopment) {
      config.headers["CF-Access-Client-Id"] =
        process.env.REACT_APP_ACCESS_CLIENT_ID || "";
      config.headers["CF-Access-Client-Secret"] =
        process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET || "";
    }
    return config;
  },
  (err) => Promise.reject(err)
);

APIV2.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const APIV3 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE + "api/v3",
});
APIV3.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (isDevelopment) {
      config.headers["CF-Access-Client-Id"] =
        process.env.REACT_APP_ACCESS_CLIENT_ID || "";
      config.headers["CF-Access-Client-Secret"] =
        process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET || "";
    }
    return config;
  },
  (err) => Promise.reject(err)
);
APIV3.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
