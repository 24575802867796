import {
  Box,
  Divider,
  IconButton,
  Modal,
  Popover,
  Slide,
  Slider,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { Icon } from "components/Icon";
import { Item } from "components/Item";
import { useEffect, useState } from "react";

const RangeBoxShadow =
  "0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);";

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 4,
  padding: "0px !important",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#4353FF",
    " &.Mui-disabled": {
      backgroundColor: theme.palette.neutral[400],
    },
    boxShadow: RangeBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: RangeBoxShadow,
      "@media (hover: none)": {
        boxShadow: RangeBoxShadow,
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    borderRadius: "2px !important",
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.neutral[500],
    borderRadius: "2px !important",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "transparent",
    width: "0px",
    height: "0px",
  },
}));

const linearValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const actualValues = [
  5, 10, 20, 50, 100, 200, 500, 1000, 1500, 2000, 5000, 9999,
];

function mapLinearToActual(value: any) {
  const range = actualValues.length - 1;
  const step = 1 / range;
  const index = Math.round(value / step);
  return actualValues[index];
}
function mapActualToLinear(value: any) {
  const index = actualValues.indexOf(value);
  const range = actualValues.length - 1;
  return index / range;
}

export const Price = ({ setFilter, filter }: any): JSX.Element => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<any>([
    mapActualToLinear(filter.min_price),
    mapActualToLinear(filter?.max_price),
  ]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    setValue([
      mapActualToLinear(filter.min_price),
      mapActualToLinear(filter?.max_price),
    ]);
  }, [filter.min_price, filter?.max_price]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue([
      mapActualToLinear(filter?.min_price),
      mapActualToLinear(filter?.max_price),
    ]);
  };

  const handleSubmitClick = () => {
    const [min_price, max_price] = value;
    setFilter("min_price", mapLinearToActual(value[0]));
    setFilter("max_price", mapLinearToActual(value[1]));
    setAnchorEl(null);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const handleChangeCommitted = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <IconButton
        // disabled={disabled}
        sx={{
          height: {
            md: "35px",
          },
          display: "flex",
          alignItems: "center",
          gap: "4px",
          p: "7.5px 8px 7.5px 8px !important",
          bgcolor: palette.neutral[50],
          color: palette.neutral[900],
          borderRadius: "8px",
          transition: "color 0.3s , background-color 0.3s",
          "&:hover": {
            bgcolor: palette.neutral[100],
            transition: "color 0.3s , background-color 0.3s",
          },
          "&.Mui-focusVisible": {
            outline: `1px solid ${palette.neutral[900]}`,
          },
          ":disabled": {
            color: palette.neutral[400],
            bgcolor: palette.neutral[100],
          },
        }}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Icon
          iconSet="Money"
          sx={{ fontSize: "19px", color: palette.neutral[900] }}
        />
        <Typography variant="body2" sx={{ color: palette.neutral[900] }}>
          {filter.min_price === 5 && filter.max_price === 9999
            ? "Any Price"
            : filter.min_price && filter.max_price
            ? `$${filter.min_price} - $${filter.max_price}`
            : "Price"}
        </Typography>
        <Icon
          iconSet="Arrow2-Down"
          sx={{ fontSize: "19px", mt: 0.4, color: palette.neutral[900] }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "16px",
            boxShadow: "0px 2px 4px 0px #00000040",
            mt: { md: 2, xs: 4 },
            width: { md: "auto", xs: "100%" },
            display: { md: "unset", xs: "none" },
          },
        }}
      >
        <Box sx={{ py: 1, px: 3, width: { md: "360px", xs: "100%" } }}>
          <Item
            title="Price"
            disable
            value={`$${mapLinearToActual(value[0])} to $${mapLinearToActual(
              value[1]
            )}`}
          />
          <RangeSlider
            step={0.01}
            min={0}
            max={1}
            marks={linearValues.map((val) => ({
              value: val,
              // label: `${mapLinearToActual(val)}`,
            }))}
            value={value}
            sx={{
              mt: "28px",
              mb: "22px",
              ".css-6cwnna-MuiSlider-mark": {
                bgcolor: palette.primary.main,
              },
              ".css-1tfve6q-MuiSlider-mark": {
                bgcolor: palette.neutral[100],
              },
              ".css-1ejytfe": {
                bgcolor: palette.neutral[100],
              },
            }}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
          />
        </Box>
        <Divider
          sx={{
            borderColor: palette.neutral[100],
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <CustomButton type="fade" text="Cancel" onClick={handleClose} />
          <CustomButton
            type="primary"
            text="Apply"
            onClick={handleSubmitClick}
          />
        </Box>
      </Popover>
      <Modal open={open} sx={{ display: { md: "none", xs: "" } }}>
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid var(--light-neutral-n-100, #E9E9E9)",
              justifyContent: "space-between",
              zIndex: 12,
              bgcolor: palette.neutral.min,
              position: "fixed",
              borderRadius: "16px 16px 0 0",
              bottom: 0,
              // animation: `${popupEffect} 1s normal forwards`,
              outline: "none",
            }}
          >
            <Box
              sx={{ py: 1, px: 2, width: { md: "360px", xs: "100%" }, pt: 2 }}
            >
              <Typography variant="h6">Price</Typography>
              <Divider
                sx={{
                  borderColor: palette.neutral[100],
                  mt: 1,
                }}
              />
              <Item
                title="Price"
                disable
                value={`$${mapLinearToActual(value[0])} to $${mapLinearToActual(
                  value[1]
                )}`}
              />
              <RangeSlider
                step={0.01}
                min={0}
                max={1}
                marks={linearValues.map((val) => ({
                  value: val,
                  // label: `${mapLinearToActual(val)}`,
                }))}
                value={value}
                sx={{
                  mt: "28px",
                  mb: "22px",
                  ".css-6cwnna-MuiSlider-mark": {
                    bgcolor: palette.primary.main,
                  },
                  ".css-1tfve6q-MuiSlider-mark": {
                    bgcolor: palette.neutral[100],
                  },
                  ".css-1ejytfe": {
                    bgcolor: palette.neutral[100],
                  },
                }}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            >
              <CustomButton type="fade" text="Cancel" onClick={handleClose} />
              <CustomButton
                type="primary"
                text="Apply"
                onClick={handleSubmitClick}
              />
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};
