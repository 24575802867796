import {
  Box,
  Divider,
  Modal,
  Slide,
  TextField,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { getLocationsByPostCode } from "api/postJob/location";
import CircularProgress from "@mui/material/CircularProgress";
import { editProfileInfo } from "api/profile";
import toast from "react-hot-toast";
import { Icon } from "components/Icon";
import useAccountInfoStore from "store/zustand/accountInformation";

const Location = ({ setPages }: { setPages: any }): JSX.Element => {
  const { palette } = useTheme();
  const myEffect = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);
  }
`;
  const setAccountInfo = useAccountInfoStore(
    (state) => state.setAccountInformation
  );
  const [billingAddressField, setBillingAddressField] = useState<any>({
    Postcode: "",
    Province: "",
    longitude: "",
    latitude: "",
    location: "",
  });
  const [valid, setValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);

  const {
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const submitAddLocation = () => {
    toast.promise(
      editProfileInfo(billingAddressField).then((res) => {
        setShow(false);
        setAccountInfo(res.data);
      }),
      {
        loading: "Saving...",
        success: (res) => ``,
        error: (err) => ``,
      },
      {
        style: {
          backgroundColor: palette.primary[50],
          borderBottom: `2px solid ${palette.primary.main}`,
        },
        loading: {
          icon: (
            <Icon
              iconSet="Cached"
              sx={{
                color: palette.primary.main,
                animation: `${myEffect} 1s normal infinite`,
              }}
            />
          ),
        },
        success: {
          style: { display: "none" },
        },
        error: {
          style: { display: "none" },
        },
      }
    );
  };

  return (
    <Modal
      open={show}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Slide direction="up" in={show} mountOnEnter unmountOnExit>
        <Box
          sx={{
            // position: "absolute" as "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: { md: "16px", xs: "0" },
            py: 4,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: { md: "center", xs: "start" },
            outline: "none !important",
            width: {
              md: "917px",
              xs: "100%",
            },
            height: { md: "623px", xs: "100%" },
          }}
        >
          <Box
            sx={{
              textAlign: { md: "center", xs: "start" },
              px: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="h2">Enter Your Postcode</Typography>
            <Typography
              variant="body1"
              color={palette.neutral[700]}
              sx={{ mt: 1 }}
            >
              Tell us where you’re located
            </Typography>
          </Box>
          <Box
            sx={{
              width: { md: "334px", xs: "100%" },
              mt: { md: "64px", xs: 2 },
              px: { md: 4, xs: 2 },
            }}
          >
            <TextField
              id="postcode-basic"
              label="Postcode"
              variant="outlined"
              placeholder="e.g. M5T 1L2"
              sx={{ width: "100%" }}
              inputProps={{ maxLength: 7 }}
              value={`${billingAddressField.Postcode ?? ""}`}
              {...register("postcode", {
                required: "Postcode is required",
                onChange: (e: any) => {
                  e.persist();
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/\s+/g, "");
                  inputValue = inputValue.replace(/(.{3})(?=.)/g, "$1 ");
                  setValid(true);
                  setBillingAddressField((value: any) => {
                    return {
                      ...value,
                      Postcode: inputValue.toUpperCase(),
                    };
                  });
                  if (e.target.value.length === 7) {
                    setLoading(true);
                    getLocationsByPostCode(e.target.value.toUpperCase()).then(
                      (res) => {
                        setLoading(false);
                        if (res.features[0]) {
                          if (
                            res.features[0].properties.name === e.target.value
                          ) {
                            setBillingAddressField((value: any) => {
                              return {
                                ...value,
                                location:
                                  res.features[0]?.properties?.full_address,
                                Province:
                                  res.features[0]?.properties?.context?.region
                                    ?.name,
                                latitude:
                                  res.features[0].geometry.coordinates[0],
                                longitude:
                                  res.features[0].geometry.coordinates[1],
                              };
                            });
                          } else {
                            setValid(false);
                            setBillingAddressField((value: any) => {
                              return {
                                ...value,
                                location: "",
                                Province: "",
                                latitude: "",
                                longitude: "",
                              };
                            });
                          }
                        } else {
                          setValid(false);
                          setBillingAddressField((value: any) => {
                            return {
                              ...value,
                              location: "",
                              Province: "",
                              latitude: "",
                              longitude: "",
                            };
                          });
                        }
                      }
                    );
                  }
                  clearErrors("postcode");
                },
              })}
              InputProps={{
                // style: {
                //   marginTop: -0.5,
                // },
                endAdornment: loading && (
                  <CircularProgress
                    sx={{
                      width: "32px !important",
                      height: "32px !important",
                    }}
                  />
                ),
              }}
              helperText={
                valid === false && (
                  <Typography
                    component="span"
                    variant="label"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: palette.error.main,
                    }}
                  >
                    <>
                      <ErrorRoundedIcon sx={{ mr: 0.5, fontSize: "16px" }} />
                      Invalid Postcode, try again
                    </>
                  </Typography>
                )
              }
              FormHelperTextProps={{ sx: { mx: 0 } }}
              error={Boolean(!valid)}
            />

            <Box
              sx={{
                py: 2,
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Typography variant="subtitle2">Neighborhood</Typography>
              <Typography variant="body2" color={palette.neutral[500]}>
                {billingAddressField.location
                  ? billingAddressField.location.split(",")[1]
                  : "-"}
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: palette.neutral[100],
              }}
            />
            <Box
              sx={{
                py: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle2">Province</Typography>
              <Typography variant="body2" color={palette.neutral[500]}>
                {billingAddressField.Province
                  ? billingAddressField.Province
                  : "-"}
              </Typography>
            </Box>

            <Box sx={{ mt: { md: 0, xs: 3 } }}>
              <Box
                sx={{
                  minWidth: 48,
                  maxWidth: 48,
                  minHeight: 48,
                  maxHeight: 48,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: palette.primary[50],
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <TipsAndUpdatesOutlinedIcon
                  sx={{
                    color: palette.primary.main,
                    width: "32px",
                    height: "32px",
                    display: "block",
                  }}
                />
              </Box>
              <Typography variant="caption" color={palette.neutral[600]}>
                Taskpin uses your postcode to offer you nearby tasks. Your
                precise location will not be shared with any entities under any
                circumstances.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "208px", xs: "100%" },
              bottom: { md: 32, xs: 0 },
              position: "absolute",
              px: "16px",
              bgcolor: palette.neutral.min,
              zIndex: 100,
              py: { xs: 2, md: 0 },
            }}
          >
            <CustomButton
              type="primary"
              text="Continue"
              sx={{ width: "100%" }}
              disabled={
                !billingAddressField.location ||
                !billingAddressField.Province ||
                valid === false ||
                billingAddressField.Postcode.length !== 7
              }
              onClick={submitAddLocation}
            />
          </Box>
          {/* "C1E 9Z9" */}
          {/* M5N 4V2 */}
        </Box>
      </Slide>
    </Modal>
  );
};

export default Location;
