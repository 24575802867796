import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const Disconnect = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: { md: "100%", xs: "300px" },
        textAlign: "center",
        bgcolor: palette.neutral.min,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src="/images/svgexport-10 1.svg" alt="404" />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{ mt: "46px" }}>
          Whoops...
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="body1"
          sx={{ mt: "16px", color: palette.neutral[700] }}
        >
          We’re having issues connecting to our servers
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "32px" }}>
        <CircularProgress />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="button"
          sx={{ color: palette.neutral[700], mt: "16px" }}
        >
          Reconnecting...
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          sx={{
            mt: "40px",
            color: palette.primary.main,
            height: { md: 35, xs: 40 },
          }}
        >
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default Disconnect;
