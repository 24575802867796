import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import {
  fetchBudgetPlans,
  postJob,
  editJob,
  callAI,
} from "api/postJob/postJob";
import Cookies from "js-cookie";

import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { APP_BASE_URL } from "../../constants/config";
import {
  Attachments,
  Budget,
  Calendar,
  Date,
  Details,
  Footer,
  Header,
  Location,
  Summary,
  Title,
} from "./components";
import AlertNotif from "components/AlertNotif";
import { Toaster, toast } from "react-hot-toast";
import { Time } from "./components/Date/Time";
import LoadingPages from "components/LoadingPages";
import { fetchJob } from "api/exploreJob";
import { EditAddress } from "./components/Location/EditAddress";
import moment from "moment";
import CustomEvents from "components/CustomEvents";
interface BudgetPlanItemType {
  id: number;
  title: string;
}
export const PostJob = (): JSX.Element => {
  const defaultValues = CustomEvents();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  // const [taskData, setTaskData] = useState<{ [key: string]: any }>({});
  const [budgetPlanItem, setBudgetPlanItem] = useState<BudgetPlanItemType[]>(
    []
  );
  const { breakpoints, palette } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [locationData, setLocationData] = useState<any>();
  const [descriptionAI, setDescriptionAI] = useState<string>("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    control,
    trigger,
    setValue,
  } = useForm({ mode: "onSubmit", reValidateMode: "onBlur" });

  const { push } = useHistory();
  const token: any = Cookies.get("token")
    ? Cookies.get("token")
    : localStorage.getItem("token");
  const copyJob = Cookies.get("copyJob");
  const editJobSlug = Cookies.get("editJob");
  const taskCookie: any = Cookies.get("postTask")
    ? Cookies.get("postTask")
    : null;
  const taskEditCookie: any = Cookies.get("editJob")
    ? Cookies.get("editJob")
    : null;
  const [postThisJob, setPostThisJob] = useState<string>("");
  const [taskData, setTaskData] = useState<any>(
    JSON.parse(taskCookie) ? JSON.parse(taskCookie) : ""
  );
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const slugJob = copyJob || editJobSlug;
    if (slugJob) {
      setLoading(true);
      fetchJob(slugJob)
        .then((response) => {
          const data = {
            title: response.data.title,
            details: response.data.description,
            location: response.data.full_location,
            locationType:
              response.data.task_type === "physical"
                ? "inPerson"
                : response.data.task_type,
            due_date: response.data.due_date,
            due_time: Object.entries(response?.data.due_time).find(
              ([key, value]) => {
                return value === true;
              }
            )?.[0],
            coordinates: [response.data.longitude, response.data.latitude],
            fake_latitude: response.data.latitude,
            fake_longitude: response.data.longitude,
            timeType:
              response.data.flexible_time === 1 ? "flexible" : "certainDate",
            budget_plan: response.data.budget_plan.id,
            budget: response.data.budget,
            files: editJobSlug ? response.data.attachments : [],
            // TODO:have to add attachment
          };
          setTaskData(data);
          Object.entries(data).forEach(([key, value]) => {
            setValue(key, value);
          });
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [copyJob, editJobSlug]);

  const params = {
    title: taskData["title"],
    description: taskData["details"],
    location: taskData["location"],
    task_type: taskData["locationType"] === "remote" ? "remote" : "physical",
    due_date:
      taskData["timeType"] !== "flexible" ? taskData["due_date"] : undefined,
    due_time:
      taskData["due_time"] && taskData["timeType"] !== "flexible"
        ? [taskData["due_time"]]
        : undefined,
    payment_type: "fixed",
    longitude: taskData["coordinates"] ? taskData["coordinates"][0] : 0,
    fake_latitude: taskData["fake_longitude"] ? taskData["fake_longitude"] : 0,
    fake_longitude: taskData["fake_latitude"] ? taskData["fake_latitude"] : 0,
    latitude: taskData["coordinates"] ? taskData["coordinates"][1] : 0,
    flexible_time: taskData["timeType"] === "flexible",
    budget_plan: taskData["budget_plan"],
    budget: taskData["budget_plan"] === 0 ? taskData["budget"] : undefined,
    attachments: taskData["files"],
  };

  const createJob = () => {
    setLoading(true);
    postJob(
      params,
      token !== undefined ? token.replace(new RegExp(/'/g), '"') : ""
    )
      .then((res) => {
        if (res.success) {
          toast.custom((t) => (
            <AlertNotif title={res?.message} type="success" target={t} />
          ));
          Cookies.remove("copyJob");
          Cookies.remove("postTask");
          window.analytics.track(`webapp poster post task done`, defaultValues);
          push(`/inventory/${res.data.slug}`);
        }
      })
      .catch((reason) => {
        if (reason?.response?.data?.error?.errors)
          Object.values(reason?.response?.data?.error?.errors)
            .flat()
            .map((item) => {
              return toast.custom((t) => (
                <AlertNotif title={item} type="error" target={t} />
              ));
            });
      })
      .finally(() => setLoading(false));
  };

  const EditJob = () => {
    setLoading(true);
    if (editJobSlug)
      editJob(
        editJobSlug,
        params,
        token !== undefined ? token.replace(new RegExp(/'/g), '"') : ""
      )
        .then((response) => {
          toast.custom((t) => (
            <AlertNotif title={response?.message} type="success" target={t} />
          ));
          push(`/inventory/${response.data.slug}`);
          Cookies.remove("editJob");
        })
        .catch((reason) => {
          console.log("err", reason);
          toast.custom((t) => (
            <AlertNotif
              title={reason?.response.data.error.message}
              type="error"
              target={t}
            />
          ));
        })
        .finally(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    Cookies.set("postTask", JSON.stringify(taskData), { expires: 1 });
  }, [taskData]);

  useEffect(() => {
    if (!JSON.parse(taskCookie) && location.search === "?Summary") {
      history.push({ search: "?Title" });
    }
    if (!taskEditCookie && location.hash === "#edit") {
      history.goForward();
    }
  }, [location.search, location.hash]);

  useEffect(() => {
    if (taskEditCookie) {
      history.push(`${location.pathname}${location.search}`);
    }
  }, [location.search]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (location.search === "?Title") {
      callAI(taskData.title).then((r: any) => {
        setDescriptionAI(r.data.description);
      });
      history.push({ search: "Location" });
      window.analytics.track(`webapp poster post task title`, defaultValues);
    }

    if (location.search === "?Summary") {
      if (postThisJob === "Edit") {
        EditJob();
      } else if (postThisJob === "Post") {
        createJob();
      }
    }
  };

  const updateDisable = useCallback(() => {
    if (location.search === "?Title") return !taskData["title"];
    if (location.search === "?Location")
      return (
        !taskData["locationType"] ||
        (taskData["locationType"] === "inPerson" && !taskData["coordinates"]) ||
        (taskData["locationType"] === "inPerson" && !taskData.coordinates)
      );
    if (location.search === "?Date") return !taskData["timeType"];
    if (location.search === "?Calender" && !smallSize) {
      if (moment(taskData.due_date).isBefore(moment().subtract(1, "days"))) {
        return true;
      }
      return !taskData["due_date"] || !taskData["due_time"];
    }
    if (location.search === "?Calender" && smallSize) {
      if (moment(taskData.due_date).isBefore(moment().subtract(1, "days"))) {
        return true;
      }
      return !taskData["due_date"];
    }
    if (location.search === "?Time") {
      return !taskData["due_time"];
    }
    if (location.search === "?Budget")
      return (
        taskData["budget_plan"] === undefined ||
        (taskData["budget_plan"] === 0 &&
          (taskData["budget"] < 4 || taskData["budget"] === undefined)) ||
        (taskData["budget_plan"] === 0 &&
          /^[0-9]*$/.test(taskData["budget"]) === false)
      );
    if (location.search === "?Details") return !taskData["details"];
    //  ||

    if (location.search === "?Summary" && loading) {
      return true;
    }
    if (location.search === "?Summary" && !loading) {
      return (
        !taskData["title"] ||
        taskData["title"]?.length < 9 ||
        taskData["title"]?.length > 100 ||
        !taskData["locationType"] ||
        (taskData["locationType"] === "inPerson" && !taskData["coordinates"]) ||
        !taskData["timeType"] ||
        (taskData["timeType"] === "certainDate" && !taskData["due_time"]) ||
        taskData["budget_plan"] === undefined ||
        (taskData["budget_plan"] === 0 &&
          (taskData["budget"] < 4 || taskData["budget"] === undefined)) ||
        (taskData["budget_plan"] === 0 &&
          /^[0-9]*$/.test(taskData["budget"]) === false) ||
        !taskData["details"] ||
        taskData["details"]?.length < 24 ||
        taskData["details"]?.length > 1000
      );
    }

    return false;
  }, [location.search, taskData, loading, smallSize]);

  useEffect(() => {
    fetchBudgetPlans()
      .then((response) => {
        if (response.success) setBudgetPlanItem(response.data);
      })
      .catch((reason) => console.log("error", reason));
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        bgcolor: palette.neutral.min,
        pb: { md: 30, xs: 10 },
      }}
    >
      {!isOnline ? <Redirect to="/disconnect" /> : null}
      <Toaster position="top-right" reverseOrder={false} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header step={step} taskData={taskData} />
        <Box
          sx={{ diplay: "block", mt: { md: 5, xs: 14 }, pb: { md: 0, xs: 17 } }}
        >
          {location.search === "?Title" ? (
            <Title
              reset={reset}
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              taskData={taskData}
              setTaskData={setTaskData}
              loading={loading}
            />
          ) : location.search === "?Location" ? (
            <Location
              setTaskData={setTaskData}
              reset={(data) => {
                reset(data, {
                  keepValues: true,
                });
              }}
              taskData={taskData}
              register={register}
              control={control}
              handleStep={setStep}
              setLocationData={setLocationData}
              locationData={locationData}
            />
          ) : location.search === "?Edit-address" ? (
            <EditAddress
              handleStep={setStep}
              setLocationData={setLocationData}
              locationData={locationData}
              setTaskData={setTaskData}
            />
          ) : location.search === "?Date" ? (
            <Date
              setTaskData={setTaskData}
              taskData={taskData}
              register={register}
            />
          ) : location.search === "?Budget" ? (
            <Budget
              setTaskData={setTaskData}
              taskData={taskData}
              errors={errors}
              register={register}
              control={control}
              trigger={trigger}
              budgetPlanItem={budgetPlanItem}
            />
          ) : location.search === "?Calender" ? (
            <Calendar
              setTaskData={setTaskData}
              taskData={taskData}
              register={register}
              control={control}
              copyJob={copyJob}
            />
          ) : location.search === "?Time" ? (
            <Time
              setTaskData={setTaskData}
              taskData={taskData}
              register={register}
              control={control}
            />
          ) : location.search === "?Details" ? (
            <Details
              handleStep={setStep}
              setTaskData={setTaskData}
              taskData={taskData}
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              trigger={trigger}
              descriptionAI={descriptionAI}
            />
          ) : location.search === "?Attachments" ? (
            <Attachments setTaskData={setTaskData} taskData={taskData} />
          ) : location.search === "?Summary" ? (
            <Summary taskData={taskData} handleStep={setStep} />
          ) : null}
        </Box>
        <Footer
          step={step}
          taskData={taskData}
          handleStep={setStep}
          disabled={updateDisable()}
          loading={loading}
          setPostThisJob={setPostThisJob}
          setDescriptionAI={setDescriptionAI}
        />
      </form>
    </Box>
  );
};
